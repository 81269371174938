import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import theme from "../../../themes/main.theme";

import InventoryIcon from '@mui/icons-material/Inventory';
import EmailIcon from '@mui/icons-material/Email';
import { Typography } from "@mui/material";

import useMediaQuery from '@mui/material/useMediaQuery';

const LeftBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${theme.palette.secondary.main};
    width: 250px;
    height: calc(100vh - 65px);
    position: fixed;
    top: 65px;
    left: 0;
    z-index: 998;
`;

const LeftBarItem = styled.div`
    display: flex;
    flex-direction: row;
    padding: 2vh;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
        background-color: ${theme.palette.secondary.dark};
    }
`;

const items = [
    {
        name: "Prodotti",
        path: "/products",
        icon: <InventoryIcon style={{ color: theme.text.white.main }} />
    },
    {
        name: "Email",
        path: "/email",
        icon: <EmailIcon style={{ color: theme.text.white.main }} />
    }
]

export default function LeftBar() {

    const navigate = useNavigate();
    const matches = useMediaQuery('(max-width:800px)');

    const handleRoute = path => {
        navigate(path);
    }

    return(
        <LeftBarContainer
            style={{
                display: matches && "none"
            }}
        >
            {
                items.map((item, index) => (
                    <LeftBarItem 
                        key={index}
                        onClick={() => handleRoute(item.path)}
                    >
                        {item.icon}
                        <Typography
                            variant="h6"
                            style={{
                                color: theme.text.white.main,
                                marginLeft: "2vh",
                            }}
                        >
                            {item.name}
                        </Typography>
                    </LeftBarItem>
                ))
            }
        </LeftBarContainer>
    )
}