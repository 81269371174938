import {
    REQUEST_LOGIN,
    RESOLVE_LOGIN,
    REJECT_LOGIN,
} from './auth.type';

const INITIAL_STATE = {
    status: false
};

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case REQUEST_LOGIN:
            return {
                ...state,
                status: true,
            };
        case RESOLVE_LOGIN:
            return {
                ...state,
                status: false,
                data: action.payload
            };
        case REJECT_LOGIN:
            return {
                ...state,
                status: false,
            };

        default:
            return state;
    }

};

export default reducer;