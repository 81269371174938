import Component from '@mui/material/Button';
import theme from '../../../themes/main.theme';

export default function ColorButton({ 
    color = theme.text.white.main, 
    backgroundColor = theme.palette.secondary.main, 
    children, 
    ...props 
}) { 
    return (
        <Component
            {...props}
            sx={{
                backgroundColor: backgroundColor,
                color: color,
                '&:hover': {
                    backgroundColor: color,
                    color: backgroundColor,
                }
            }}
        >
            {children}
        </Component>
    )
}