import config from './config.json';
import axios from 'axios';

let api = {}

api.getMails = async () => {
    return await axios.get(`${config.base_url}/api/mails`, {
        headers: {
            'authorization': window.sessionStorage.getItem('token')
        }
    });
}

export default api;