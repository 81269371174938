import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#212121",
            dark: "#000000",
            light: "#484848",
            lightest: "#cfcfcf"
        },
        secondary: {
            main: "#e30614",
        }
    },
    text: {
        white: {
            main: "#e4e4e4",
            dark: "#d3d3d3",
            light: "#ffffff"
        }
    },
    shadows: {
        level1: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        level2: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
        level3: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
        level4: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
        level5: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)"
    },
    transitions: {
        main: "all 0.3s cubic-bezier(.25,.8,.25,1)"
    }
})

export default theme;