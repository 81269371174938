import TextField from '@mui/material/TextField';

export default function WhiteTextField({
    color,
    ...props
}) {
    return(
        <TextField
            {...props}
            variant="outlined"
            sx={{
                "& label": {
                    color: color
                },

                "& label.Mui-focused": {
                    color: color,
                },

                "& .MuiOutlinedInput-root": {

                    color: color,

                    "& fieldset" : {
                        borderColor: color,
                    },

                    "&:hover fieldset" : {
                        borderColor: color,
                    },

                    "&.Mui-focused fieldset" : {
                        borderColor: color,
                    }
                }
            }}
        />
    )
}