import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { IconButton } from '@mui/material';
import styled from "styled-components";

import MainLayout from "../../components/layout/main.layout"
import theme from '../../themes/main.theme';

import ColorTextField from '../../components/ui/inputs/ColorTextField';
import ColorSelect from '../../components/ui/inputs/ColorSelect';
import ListIcon from '@mui/icons-material/List';
import CustomDropzone from '../../components/ui/general/CustomDropzone';

import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';

import { getBase64 } from '../../utils/data';
import api from '../../api/product.api';
import { useNavigate, useParams } from "react-router-dom";

import useMediaQuery from '@mui/material/useMediaQuery';

const CustomImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export default function AddProductPage() {

    const navigate = useNavigate();
    const { id } = useParams();

    const matches = useMediaQuery('(max-width:800px)');

    const [files, setFiles] = React.useState([]);
    const [openKeyModal, setOpenKeyModal] = React.useState(false);
    const [extraParams, setExtraParams] = React.useState([]);
    const [formData, setFormData] = React.useState({});

    //#region Handlers
    const handleOpenKeyModal = () => setOpenKeyModal(true);
    const handleCloseKeyModal = () => setOpenKeyModal(false);

    const handleSave = () => {

        const data = {
            id: id,
            name : formData?.nome || '',
            brand : formData?.marca || '',
            km : formData?.chilometraggio || '',
            type : formData?.tipologia || '',
            price : formData?.prezzo || '',
            addons: JSON.stringify(extraParams),
            b64Image: JSON.stringify(files),
        }

        api.updateProduct(data).then(res => {
            if(res.data.state) {
                navigate('/products');
            }
        })
    }

    const handleDrop = _files => {
        _files.map((file, index) => {
            if(files.length < 6) {
                getBase64(file, res => {
                    setFiles(files => [...files, {
                        name: file.name,
                        data: res,
                        selected: false
                    }]);
                    if(index === 0) {
                        setFiles(files => files.map((file, index) => {
                            if(index === 0) {
                                file.selected = true;
                            }
                            return file;
                        }));
                    }
                });
            }
        })
    }

    const handleRemoveImage = index => {
        setFiles(files => files.filter((file, i) => i !== index));
    }

    const handleSelectImage = index => {
        setFiles(files => files.map((file, i) => {
            if(i === index) {
                file.selected = true;
            } else {
                file.selected = false;
            }
            return file;
        }));
    }

    const handleAddExtraParam = () => {
        setExtraParams(extraParams => [...extraParams, {
            key: "",
            value: ""
        }]);
    }

    const handleRemoveExtraParam = index => {
        console.log(index);
        console.log(extraParams);
        setExtraParams(extraParams => extraParams.filter((param, i) => i !== index));
    }

    const handleFormDataChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name || "tipologia"]: event.target.value
        });
    };

    const handleExtraParamsChange = (event, index) => {
        setExtraParams(extraParams => extraParams.map((param, i) => {
            if(i === index) {
                param[event.target.name] = event.target.value;
            }
            return param;
        }));
    }

    const handleSearch = () => {

    }
    //#endregion

    React.useEffect(() => {
        api.getProductDetail(id).then(res => {
            if(res.data.state) {
                const data = res.data.data;

                setFormData({
                    nome: data.name,
                    marca: data.brand,
                    chilometraggio: data.km,
                    tipologia: data.type,
                    prezzo: data.price,
                });
                setExtraParams(JSON.parse(data.addons));
                setFiles(JSON.parse(data.b64Image));
            }
        })
    }, [])

    return(
        <MainLayout>
            {
                //#region Extra Key Modal
            }

            <Modal
                open={openKeyModal}
                onClose={handleCloseKeyModal}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: !matches ? "40vw" : "80vw",
                    height: "50vh",
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: "2vh"
                    }}>
                        <Typography variant="h6">
                            Gestione parametri extra
                        </Typography>

                        <IconButton
                            onClick={handleAddExtraParam}
                            style={{
                                marginLeft: 'auto',
                            }}
                        >
                            <AddIcon 
                                style={{
                                    fontSize: "30px",
                                }}
                            />
                        </IconButton>
                    </div>
                    
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxHeight: '40vh',
                        overflowY: 'scroll',
                    }}>

                        {
                            Object.keys(extraParams).length === 0 ? (
                                <Typography sx={{ mt: 2 }}>
                                    Non hai aggiunto parametri extra...
                                </Typography>
                            ) : (
                                extraParams.map((item, index) => {
                                    return(
                                        <div style={{
                                            display: 'flex',
                                            marginBottom: '1vh',
                                        }}>
                                            <ColorTextField
                                                name="key"
                                                value={extraParams[index].key}
                                                onChange={ (e) => handleExtraParamsChange(e, index) }
                                                fullWidth
                                                color={theme.palette.primary.main}
                                                label="Nome parametro"
                                                size="small"
                                                style={{
                                                    marginRight: '1vh',
                                                }}
                                            />
        
                                            <ColorTextField 
                                                name="value"
                                                value={extraParams[index].value}
                                                onChange={ (e) => handleExtraParamsChange(e, index) }
                                                fullWidth
                                                color={theme.palette.primary.main}
                                                label="Valore parametro"
                                                size="small"
                                                style={{
                                                    marginRight: '1vh',
                                                }}
                                            />
    
                                            <IconButton
                                                onClick={() => handleRemoveExtraParam(index)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    )
                                })
                            )
                        }

                    </div>

                </Box>
            </Modal>

            {
                //#endregion
            }

            {
                //#region Breadcrumbs
            }

            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/products">
                    Prodotti
                </Link>
                <Typography color="text.primary">Aggiungi</Typography>
            </Breadcrumbs>

            <div style={{
                display: "flex",
                alignItems: "center",
            }}>

                <Typography
                        variant="h4"
                        style={{
                            color: theme.palette.primary.light,
                            marginTop: '2vh'
                        }}
                >
                    Modifica Prodotto
                </Typography>

                <IconButton
                    onClick={handleOpenKeyModal}
                    style={{
                        marginLeft: 'auto',
                        alignSelf: 'end',
                    }}
                >
                    <ListIcon 
                        style={{
                            fontSize: '30px',
                            color: theme.palette.primary.light,
                        }}
                    />
                </IconButton>

                <IconButton
                    onClick={handleSave}
                    style={{
                        alignSelf: 'end',
                    }}
                >
                    <SaveIcon 
                        style={{
                            fontSize: '30px',
                            color: theme.palette.primary.light,
                        }}
                    />
                </IconButton>

            </div>

            {
                //#endregion
            }

            {
                //#region Form
            }

                <div style={{
                    display: 'flex',
                    marginTop: '2vh',
                    flexFlow: !matches ? 'row' : 'column',
                }}>
                    <ColorTextField
                        name="nome"
                        value={formData?.nome || ''}
                        onChange={handleFormDataChange}
                        key={'nome'}
                        color={theme.palette.primary.light}
                        size="small"
                        label="Nome Prodotto"
                        fullWidth
                        style={{
                            marginRight: '1vh',
                            marginBottom: '1vh',
                        }}
                    />

                    <ColorTextField
                        name="chilometraggio"
                        value={formData?.chilometraggio || ''}
                        onChange={handleFormDataChange}
                        key={'chilometraggio'}
                        color={theme.palette.primary.light}
                        size="small"
                        label="Chilometraggio"
                        fullWidth
                        style={{
                            marginRight: '1vh',
                            marginBottom: '1vh',
                        }}
                    />

                    <ColorTextField
                        name="marca"
                        value={formData?.marca || ''}
                        onChange={handleFormDataChange}
                        key={'marca'}
                        color={theme.palette.primary.light}
                        size="small"
                        label="Marca"
                        fullWidth
                        style={{
                            marginRight: '1vh',
                            marginBottom: '1vh',
                        }}
                    />

                    <ColorTextField 
                        name="prezzo"
                        value={formData?.prezzo || ''}
                        onChange={handleFormDataChange}
                        key={'prezzo'}
                        color={theme.palette.primary.light}
                        size="small"
                        label="Prezzo"
                        fullWidth
                        style={{
                            marginRight: '1vh',
                            marginBottom: '1vh',
                        }}
                    />

                    <ColorSelect
                        label="tipologia"
                        name="tipologia"
                        value={formData?.tipologia}
                        onChange={handleFormDataChange}
                        key={'tipologia'}
                        color={theme.palette.primary.light}
                        size="small"
                        fullWidth
                        data={[
                            {
                                label: 'Nuovo',
                                value: 'nuovo',
                            },
                            {
                                label: 'Usato',
                                value: 'usato',
                            },
                        ]}
                    />
                </div>

            {
                //#endregion
            }

            {
                //#region Dropzone
            }

            <div style={{
                display: 'flex',
                flexFlow: 'column',
                marginTop: '2vh'
            }}>

                <CustomDropzone 
                    onDrop={handleDrop}
                />

                <div style={{
                    display: 'flex',
                    flexFlow: !matches ? 'row' : 'column',
                    marginTop: '2vh',
                    cursor: 'pointer',
                    marginBottom: matches ? '5vh' : "0",
                }}>
                    {
                        files.map((file, index) => (
                            <div
                                onClick={() => {handleSelectImage(index)}}
                                key={index}
                                style={{
                                    width: !matches ? "calc(100% / 6 - 4vh)" : "calc(100% - 4vh)",
                                    height: "12vh",
                                    padding: "2vh",
                                    position: "relative",
                                    border: file.selected ? `2px solid ${theme.palette.primary.light}` : 'none',
                                }}
                            >
                                <IconButton
                                    onClick={() => handleRemoveImage(index)}
                                    style={{
                                        position: 'absolute',
                                        background: theme.palette.secondary.main,
                                        color: theme.text.white.main,
                                        right: '5px',
                                        top: '5px'
                                    }}
                                >
                                    <DeleteIcon 
                                        style={{
                                            fontSize: '20px',
                                        }}                                    
                                    />
                                </IconButton>
                                <CustomImage src={file.data} alt={file.name} key={file.name} />
                                {
                                    !matches && (
                                        <Typography style={{
                                            marginTop: "2vh"
                                        }}>
                                            { file.name }
                                        </Typography>
                                    )
                                }
                            </div>
                        ))
                    }

                </div>

            </div>

            {
                //#endregion
            }
        </MainLayout>
    )
}