import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import api from '../../api/product.api';

import { IconButton, Typography } from "@mui/material";
import MainLayout from "../../components/layout/main.layout";
import TablePaginated from "../../components/ui/general/TablePaginated";
import theme from "../../themes/main.theme";

import ColorSearchTextField from "../../components/ui/inputs/ColorSearchTextField";
import ColorButton from "../../components/ui/inputs/ColorButton";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

export default function ProductPage() {

    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [searchField, setSearchField] = useState("");
    const [searchRecords, setSearchRecords] = useState([]);

    const handleOpen = product => {
        setSelectedProduct(product);
        setOpen(true)
    };

    const handleClose = () => {
        setSelectedProduct(null);
        setOpen(false)
    };

    const handleDelete = async () => {
        const response = await api.deleteProduct(selectedProduct?._id);
        if(response.data.state) {
            const newProducts = products.filter(product => product._id !== selectedProduct._id);
            setProducts(newProducts);
            handleClose();
        }
    }

    const handleSearchFieldChange = event => {
        setSearchField(event.target.value);
        handleSearch();
    }

    const handleSearch = () => {
        const filteredProducts = products.filter(product => {
            return (
                product.name.toLowerCase().includes(searchField.toLowerCase()) ||
                product.km.toLowerCase().includes(searchField.toLowerCase()) ||
                product.type.toLowerCase().includes(searchField.toLowerCase())
            )
        });

        setSearchRecords(filteredProducts);
    }

    useEffect(() => {
        api.getProducts().then((response) => {
            if(response.data.state) {
                setProducts(response.data.data.products.map((item, key) => {
                    return {
                        _id: item._id,
                        km: item.km,
                        name: item.name,
                        type: item.type,
                        actions: <>
                            <IconButton
                                onClick={() => {navigate(`/editProduct/${item._id}`)}}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => handleOpen(item)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </>
                    }
                }));
            }
        })
    }, [])

    return (
        <MainLayout>
            {
                //#region Modal delete
            }

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4
                }}>
                    <Typography variant="h6" component="h2">
                        Conferma eliminazione prodotto
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        Sei sicuro di voler eliminare il seguente prodotto: <br />
                    </Typography>
                    <Typography>
                        <strong>{selectedProduct?.name}</strong> <br />
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <ColorButton
                            backgroundColor={theme.text.white.main}
                            color={theme.palette.primary.main}

                            onClick={handleClose}
                            style={{
                                marginRight: "1vh"
                            }}
                        >
                            Annulla
                        </ColorButton>
                        <ColorButton
                            onClick={handleDelete}
                        >
                            Elimina
                        </ColorButton>
                    </Box>
                </Box>
            </Modal>

            {
                //#endregion
            }

            {
                //#region Breadcrumb
            }

            <Typography
                variant="h4"
                style={{
                    color: theme.palette.primary.light,
                }}
            >
                Prodotti
            </Typography>

            {
                //#endregion
            }

            {
                //#region Table Header
            }

            <div style={{
                display: 'flex',
                marginTop: '2vh',
            }}>
                <ColorSearchTextField
                    value={searchField}
                    onChange={handleSearchFieldChange}          
                    color={theme.palette.primary.light}
                    size="small"
                />

                <IconButton
                    onClick={() => {navigate("/addProduct")}}
                    style={{
                        marginLeft: 'auto',
                    }}
                >
                    <AddCircleOutlineIcon />
                </IconButton>
            </div>

            {
                //#endregion
            }

            {
                //#region Table
            }

            <TablePaginated
                style={{
                    marginTop: "2vh",
                }}
                rowsNames={[
                    "Codice Prodotto",
                    "Nome Prodotto",
                    "Chilometraggio",
                    "Tipologia",
                    "Azioni"
                ]}
                rows={
                    searchField.length > 0 ? searchRecords : products
                }
            />

            {
                //#endregion
            }
        </MainLayout>
    );
}