
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function ColorSelect({
    color,
    data = [],
    label,
    onChange,
    ...props
}) {

    return(
        <FormControl
            {...props}
            sx={{
                '& .MuiSelect-outlined' : {
                    color: color
                },

                '& .MuiInputLabel-root' : {
                    color: color
                },

                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: color,
                },

                '& .MuiSelect-iconOutlined' : {
                    fill: color
                },
            }}
        >
            <InputLabel> {label} </InputLabel>
            <Select
                onChange={onChange}
            >
                {
                    data.map((item, index) => {
                        return(
                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
    )
}