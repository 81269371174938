export default function LoginLayout({ 
    children, 
    ...props 
}) {
    return(
        <div 
            {...props}
            style={{
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '300px',
                margin: '0 auto'
            }}
        >
            {children}
        </div>
    )
}