
import LoginLayout from '../components/layout/login.layout';
import ColorTextField from '../components/ui/inputs/ColorTextField';
import ColorButton from '../components/ui/inputs/ColorButton';
import theme from '../themes/main.theme';
import api from '../api/auth.api';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

export default function LoginPage() {

    const navigate = useNavigate();

    const [formData, setFormData] = useState({});

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleLogin = async () => {
        if(formData?.username && formData?.password) {
            const response = await api.login(formData.username, formData.password);
            if(response.data.state) {
                window.sessionStorage.setItem('token', response.data.data.token);
                navigate("/products");
            }
        }
    }

    return (
        <LoginLayout>
            <img src="logo.jpeg" 
                style={{
                    width: '100%',
                }}
            />

            <ColorTextField 
                name="username"
                value={formData?.username}
                onChange={handleChange}
                color={theme.palette.primary.main}
                size="small"
                fullWidth
                label="Email"
                style={{
                    marginTop: '2vh'
                }}
            />

            <ColorTextField
                name="password"
                value={formData?.password}
                onChange={handleChange}
                color={theme.palette.primary.main}
                size="small"
                fullWidth
                label="Password"
                type="password"
                style={{
                    marginTop: '1vh'
                }}
            />

            <ColorButton
                onClick={handleLogin}
                fullWidth
                style={{
                    marginTop: '2vh'
                }}
            > 
                Accedi 
            </ColorButton>

        </LoginLayout>
    );
}