import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import api from '../../api/mail.api';

import { IconButton, Typography } from "@mui/material";
import MainLayout from "../../components/layout/main.layout";
import TablePaginated from "../../components/ui/general/TablePaginated";
import theme from "../../themes/main.theme";

import ColorSearchTextField from "../../components/ui/inputs/ColorSearchTextField";

export default function EmailPage() {

    const navigate = useNavigate();

    const [products, setProducts] = useState([]);
    const [searchField, setSearchField] = useState("");
    const [searchRecords, setSearchRecords] = useState([]);

    const handleSearchFieldChange = event => {
        setSearchField(event.target.value);
        handleSearch();
    }

    const handleSearch = () => {
        const filteredProducts = products.filter(product => {
            return (
                product.mail.toLowerCase().includes(searchField.toLowerCase())
            )
        });

        setSearchRecords(filteredProducts);
    }

    useEffect(() => {
        api.getMails().then((response) => {
            if(response.data.state) {
                setProducts(response.data.data.mails.map((item, key) => {
                    return {
                        _id: item._id,
                        mail: item.mail
                    }
                }));
            }
        })
    }, [])

    return (
        <MainLayout>

            {
                //#region Breadcrumb
            }

            <Typography
                variant="h4"
                style={{
                    color: theme.palette.primary.light,
                }}
            >
                Email
            </Typography>

            {
                //#endregion
            }

            {
                //#region Table Header
            }

            <div style={{
                display: 'flex',
                marginTop: '2vh',
            }}>
                <ColorSearchTextField
                    value={searchField}
                    onChange={handleSearchFieldChange}          
                    color={theme.palette.primary.light}
                    size="small"
                />

            </div>

            {
                //#endregion
            }

            {
                //#region Table
            }

            <TablePaginated
                style={{
                    marginTop: "2vh",
                }}
                rowsNames={[
                    "Codice Inserimento",
                    "Email"
                ]}
                rows={
                    searchField.length > 0 ? searchRecords : products
                }
            />

            {
                //#endregion
            }
        </MainLayout>
    );
}