import React from "react";
import Dropzone from 'react-dropzone';
import styled from "styled-components";
import theme from "../../../themes/main.theme";

const SectionContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 20vh;
    border: 2px dashed ${theme.text.white.dark};
    cursor: pointer;
    transition: ${theme.transitions.main};
    &:hover {
        border: 2px dashed ${theme.palette.primary.light};
    }
`;

function CustomDropzone({
    onDrop
}) {
    return(
        <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)}>
            {({getRootProps, getInputProps}) => (
                <section style={{
                    width: '100%',
                }}>
                    <SectionContainer {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>Trascina qua i tuoi file o clicca per il caricamento</p>
                    </SectionContainer>
                </section>
            )}
        </Dropzone>
    )
}

export default CustomDropzone;