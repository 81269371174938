import { IconButton } from "@mui/material";
import styled from "styled-components";
import theme from "../../../themes/main.theme";
import { useNavigate } from "react-router-dom";

import LogoutIcon from '@mui/icons-material/Logout';

const TopBarContainer = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${theme.palette.secondary.main};
    height: 65px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    align-items: center;
    box-shadow: ${theme.shadows.level2}
`;

const TopBarSpacer = styled.div`
    height: 65px;
`;

export default function TopBar() {

    const navigate = useNavigate();

    const handleLogout = () => {
        sessionStorage.removeItem("token");
        navigate("/login");
    }

    return(
        <>
            <TopBarContainer>
                <img src="/logowhite.svg" 
                    style={{
                        height: "45px",
                        marginLeft: "1vh"
                    }}
                />

                <IconButton
                    onClick={handleLogout}
                    style={{
                        color: theme.text.white.main,
                        marginLeft: "auto",
                        marginRight: "1vh"
                    }}
                >
                    <LogoutIcon 
                        style={{
                            fontSize: "30px"
                        }}
                    />
                </IconButton>
            </TopBarContainer>
            <TopBarSpacer />
        </>
    )
}