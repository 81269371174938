import ColorTextField from "../../../components/ui/inputs/ColorTextField";
import { InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import theme from "../../../themes/main.theme";

export default function ColorSearchTextField({
    color = theme.palette.primary.lightest, 
    ...props
}) {
    return(
        <ColorTextField
            {...props}
            color={color}
            label="Cerca" 
            variant="outlined"
            size="small"
            InputProps={{
                endAdornment: (
                    <InputAdornment 
                        position="end"
                    >
                        <SearchIcon 
                            style={{
                                fill: theme.palette.primary.light
                            }}
                        />
                    </InputAdornment>
                ),
            }}
        />
    )
}