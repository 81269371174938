import config from './config.json';
import axios from 'axios';

let api = {}

api.getProducts = async () => {
    return await axios.get(`${config.base_url}/api/products`, {
        headers: {
            'authorization': window.sessionStorage.getItem('token')
        }
    });
}

api.deleteProduct = async id => {
    return await axios.post(`${config.base_url}/api/deleteProduct`, {
        id: id
    }, {
        headers: {
            'authorization': window.sessionStorage.getItem('token')
        }
    });
}

api.addProduct = async product => {
    return await axios.post(`${config.base_url}/api/product`, {
        product
    }, {
        headers: {
            'authorization': window.sessionStorage.getItem('token')
        }
    });
}

api.getProductDetail = async id => {
    return await axios.post(`${config.base_url}/api/productDetail`, {
        id: id
    }, {
        headers: {
            'authorization': window.sessionStorage.getItem('token')
        }
    });
}

api.updateProduct = async product => {
    return await axios.post(`${config.base_url}/api/updateProduct`, {
        product
    }, {
        headers: {
            'authorization': window.sessionStorage.getItem('token')
        }
    });
}

export default api;