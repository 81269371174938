import { BrowserRouter, Route, Routes } from 'react-router-dom';

import PrivateRoute from './guards/PrivateRoute';

import LoginPage from './pages/login.page';
import ProductPage from './pages/product/product.page';
import EditProductPage from './pages/product/editProduct.page';
import AddProductPage from './pages/product/addProduct.page';
import EmailPage from './pages/email/email.page';

function App() {
	return (
        <BrowserRouter>
            <Routes>
                <Route path="/">
                    <Route exact path='/' element={<LoginPage/>}/>
                    <Route exact path='/login' element={<LoginPage/>}/>
                </Route>
                <Route path="/" element={<PrivateRoute/>}>
                    <Route exact path='/home' element={<ProductPage />}/>
                    <Route exact path='/products' element={<ProductPage />}/>
                    <Route exact path='/addproduct' element={<AddProductPage />}/>
                    <Route exact path='/editproduct/:id' element={<EditProductPage />}/>
                    <Route exact path='/email' element={<EmailPage />}/>
                </Route>
                <Route path="*" element={<h1>404</h1>} />
            </Routes>
        </BrowserRouter>
	);
}

export default App;
