import config from './config.json';
import axios from 'axios';

let api = {}

api.login = async (
    username,
    password
) => {
    return await axios.post(`${config.base_url}/login`, {
        username: username,
        password: password
    });
}

export default api;