
import LeftBar from "../ui/navigation/LeftBar";
import TopBar from "../ui/navigation/TopBar";

import useMediaQuery from '@mui/material/useMediaQuery';

export default function MainLayout({ children }) {

    const matches = useMediaQuery('(max-width:800px)');

    return (
        <>
            <TopBar />
            <LeftBar />
            <div style={{
                width: !matches ? "calc(100vw - 250px - 4vh)" : "calc(100vw - 4vh)",
                height: "calc(100vh - 65px)",
                position: "fixed",
                overflowY: "scroll",
                overflowX: "hidden",
                top: "65px",
                left: !matches ? "250px" : "0",
                padding: "2vh"
            }}>
                {children}
            </div>
        </>
    );
}